/* @font-face {
  font-family: "SpaceGrotesk";
  src: url("https://fonts.googleapis.com");
  src: url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&display=swap");
} */

/* @font-face {
  font-family: "SpaceGrotesk Light";
  src: url("./assets/fonts/static/SpaceGrotesk-Light.ttf");
} */

html {
  scroll-behavior: smooth;
}

.App {
  text-align: center;
  /* font-family: 'SpaceGrotesk'; */
  font-family: 'Space Grotesk', sans-serif;
  background-color: #0d0d1e;
  color: white;
  padding-top: 6rem;
}

.logo {
  display: inline;
  width: 12rem;
}
.text-logo{
  width: 14rem;
}

.logo-container {
  display: flex;
  justify-content: flex-start !important;
}

.logo-header {
    /* grid-column: span 2 / span 2 !important; */
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.menu-button{
  /* margin-right: 0rem; */
}


.nav-bg {
  background-color: #0d0d1e !important;
  font-weight: bold;
  padding-left: 1rem;
  padding-right: 1rem;
}

.nav-collapse {
  background-color: #0d0d1e;
}

.dropdown {
  background-color: #26273d;
  color: white;
  position: fixed;
  display: none;
  float: inherit;
  height: auto;
  top: 5rem;
  left: 25%;
  right: 25%;
  z-index: 1;
  transition: all .5s ease-in-out;
  -webkit-transition: all .5s ease-in-out;
  /** Chrome & Safari **/
  -moz-transition: all .5s ease-in-out;
  /** Firefox **/
  -o-transition: all .5s ease-in-out;
}

/**
* When changing the number of items in the DropdownBox, the second value
* of the translate property has to be changed accordingly.
*/
.up {
  transform: translate(0, -480px);
  -webkit-transform: translate(0, -480px);
  -o-transform: translate(0, -480px);
  -moz-transform: translate(0, -480px);
}

.dd-item-title {
  /* font-family: 'SpaceGrotesk'; */
  font-family: 'Space Grotesk', sans-serif;
  font-size: 1.125rem;
  font-weight: bold !important;
  color: #c1c1d3;
  padding-bottom: 0.25rem;
}

.dd-item-description {
  font-size: 1rem !important;
  font-weight: 300;
  font-stretch: normal;
  /* font-family: 'SpaceGrotesk Light'; */
  font-family: 'Space Grotesk', sans-serif;
  color: #c1c1d3;
}

.dd-item-icon {
  max-width: 2rem;
  margin-right: 0.5rem;
}

.button {
  border: 0px !important;
  border-style: hidden !important;
}

.navbar-container {
  z-index: 3;
  position: fixed !important;
  width: 100%;
  padding-left: 5rem !important;
  padding-right: 5rem !important;
  /* overflow: hidden; */
}

.nav-icons {
  width: 2rem;
}
.nav-icons-link{
  padding: 0px !important;
}

.click-area {
  position: absolute;
  width: 100%;
  height: 100vh;
  background-color: transparent;
  z-index: 0;
}

.drop-item {
  width: 100%;
  padding-bottom: .75rem;
  padding-top: .75rem;
}

.gap-4rem {
  grid-column-gap: 4rem !important;
}

.tippy-content .bg-white {
  background-color: #26273d !important;
}

.footer {
  background-color: #040414;
  padding-top: 3.5rem;
  padding-bottom: 2rem;
  padding-left: 5.8rem;
  padding-right: 5.8rem;

}

.footer .text {
  /* font-family: "SpaceGrotesk Light"; */
  font-family: 'Space Grotesk', sans-serif;
  font-size: 0.875rem;
  font-weight: 300;
  color: #c1c1d3;
  letter-spacing: 0.01px;
}
.footer .text2{
  /* font-family: 'SpaceGrotesk'; */
  font-family: 'Space Grotesk', sans-serif;
  font-size: 0.875rem;
  font-weight: 500 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.01px;
  color: #c1c1d3;
}

.footer .title {
  /* font-family: 'SpaceGrotesk'; */
  font-family: 'Space Grotesk', sans-serif;
  font-size: 1rem;
  font-weight: bold !important;
  font-stretch: normal;
  color: #c1c1d3;
  letter-spacing: 0.01px;
}

.bottom-title {
  opacity: 0.7;
  font-size: 0.75rem !important;
  font-weight: 300 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.01px;
  color: white;
}

.button-yellow {
  /* font-family: "SpaceGrotesk" !important; */
  font-family: 'Space Grotesk', sans-serif !important;
  background-color: #f2bf30 !important;
  color: #0d0d1e !important;
  font-weight: bold !important;
  text-transform: initial !important;
  box-shadow: initial !important;
}

button:focus {
  outline: 0 !important;
}

.drop .material-icons {
  display: none;
}

.titles {
  color: white;
  font-size: 4rem;
  font-weight: bold;
  line-height: 1.13;
}

.titles-yellow {
  color: #0d0d1e;
  font-size: 4rem;
  background-color: #f2bf30;
  font-weight: bold;
  display: inline;
  line-height: 0px;
}

.titles-max {
  color: white;
  font-size: 7rem;
  font-weight: bold;
  line-height: 1.16;
}

.titles-max-details {
  color: white;
  font-size: 6.5rem;
  font-weight: bold;
  /* line-height: 1.16; */
}

.titles-max-contact-details {
  /* font-family: 'SpaceGrotesk'; */
  font-family: 'Space Grotesk', sans-serif;
  font-size: 4.561rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.16;
  padding-top: 7.5rem;
}


.icon-details {
  width: 6.2rem;
  /* height: 4.375rem; */
}

.container-text-box {
  display: flex;
  justify-content: center;

}

.text-box {
  background-color: #26273d !important;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 16.063rem !important;
}

.text-box-address {
  background-color: #26273d !important;
  border-radius: 4rem !important;
  font-size: 1rem;
  font-weight: 300;
  width: 16.063rem !important;
  /* font-family: "SpaceGrotesk Light"; */
  font-family: 'Space Grotesk', sans-serif;
}

.tb-title {
  /* font-family: "SpaceGrotesk Light"; */
  font-family: 'Space Grotesk', sans-serif;
  font-size: 1rem;
  font-weight: 300;
  color: white;
}

.tb-description {
  /* font-family: "SpaceGrotesk"; */
  font-family: 'Space Grotesk', sans-serif;
  font-size: 1rem;
  font-weight: bold;
}

.titles-yellow-max {
  color: #0d0d1e;
  background-color: #f2bf30;
  display: inline;
  padding-left: .5rem;
  padding-right: .5rem;
  padding-top: 0rem;
  padding-bottom: 0rem;
}

.titles-yellow-max-details {
  color: #0d0d1e;
  background-color: #f2bf30;
  display: inline;
  padding-top: 0rem;
  padding-bottom: 0rem;
  line-height: 1.16;
}

.sub-title {
  font-size: 1.5rem;
  /* font-family: "SpaceGrotesk Light"; */
  font-family: 'Space Grotesk', sans-serif;
  padding: 20rem;
  color: white;
}

.sub-title-gray {
  font-size: 1.5rem;
  /* font-family: "SpaceGrotesk Light"; */
  font-family: 'Space Grotesk', sans-serif;
  color: #c1c1d3;
  width: 31.625rem;
  /* height: 8.563rem; */
  padding-bottom: 2rem;
  padding-right: 0rem;
  padding-left: 0rem;
}

.sub-title-gray-details {
  /* font-family: "SpaceGrotesk"; */
  font-family: 'Space Grotesk', sans-serif;
  color: #c1c1d3;
  width: 31.625rem;
  padding-bottom: 1.5rem;
  padding-top: 1.5rem !important;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.5;
  letter-spacing: 0.01px;
  width: 25.063rem;
}


.sub-title-white-details {
  font-size: 1rem;
  /* font-family: "SpaceGrotesk Light"; */
  font-family: 'Space Grotesk', sans-serif;
  color: white;
  font-weight: 300;
  line-height: 1.5;
}

.p-sub-yellow {
  padding-right: 1%;
  font-size: 1.875rem;
  color: #f2bf30;
  font-weight: bold;
}

.p-sub-grey {
  font-size: 1.875rem;
  color: #c1c1d3;
  font-weight: bold;
}

.p1-gray {
  /* font-family: "SpaceGrotesk"; */
  font-family: 'Space Grotesk', sans-serif;
  color: #c1c1d3;
  font-size: 1.125rem;
  font-weight: 300;
}

.vertical-text {
  transform: rotate(270deg);
  font-size: 6.5rem;
  font-weight: 500;
  height: fit-content;
}

.text-over {
  position: absolute;
  top: 50%;
  left: 38%;
  transform: translate(-50%, -50%);
  font-size: 1rem;
  font-weight: 500;
}

.text-over .yellow {
  display: inline;
}

.titles-container {
  padding-left: 12.3rem;
  padding-right: 12.3rem;
}

.container-text-box {
  padding-bottom: 1rem;
}

.container-img {
  background-size: auto 100%;
  background-image: url('./assets/elements/SVG ASSETS/assets-vlines.svg');
}

.container-bg {
  background-image: url('./assets/elements/SVG ASSETS/Triangles.svg');
  background-repeat: no-repeat;
  background-size: 100%;
  background-position-y: center;
}

.container-bg-details {
  background-image: url('./assets/elements/SVG ASSETS/Triangles.svg');
  background-repeat: no-repeat;
  background-size: 100%;
  background-position-y: center;
}

.contact {
  width: 38.5rem;
  height: 53rem;
  margin-bottom: 5rem;
}

.container-bg2 {
  padding-left: 20%;
  position: relative;
  text-align: center;
  width: -webkit-fill-available;
  margin-top: -5rem;
  margin-bottom: -15rem;
}

.container-img2 {
  background-image: url('./assets/elements/SVG ASSETS/assets-vlines.svg');
}

.container-titles-details {
  margin-top: 8.5rem;
  padding-left: 12.3rem;
  margin-bottom: 7.5rem;
}

.container-titles-details-down {
  padding-left: 12.3rem;
  margin-bottom: 10rem;
}

.container-titles-details-2 {
  margin-bottom: 3rem;
}

.text-details {
  font-size: 1rem;
  font-weight: 500;
  color: #c1c1d3;
}

.long-description {
  width: 46rem;
}

.short-description {
  width: 11.875rem;
  margin-right: 8.125rem;
}

.container-titles-details .p1-gray {
  width: 31rem;
}

.nav-links {
  -webkit-justify-content: flex-end !important;
  justify-content: flex-end !important;
}

.nav-links a {
  padding-left: .5rem !important;
  padding-right: .5rem !important;
}

.nav-button a {
  justify-content: center !important;
}

.full-web-kit {
  width: -webkit-fill-available;
}

.simple-card {
  padding: 2rem;
}

.card-img {
  max-width: 9.1rem;
  display: inline;
}

.card-img2 {
  max-width: 4.6rem;
}

.card-title {
  color: #c1c1d3;
  font-size: 1.875rem;
  font-weight: bold;
}

.card-title-1 {
  color: #c1c1d3;
  font-size: 1.5rem;
  font-weight: bold;
}

.card-description {
  color: #c1c1d3;
  font-size: 1rem;
  font-weight: 300;
}

.crypto-card {
  padding-bottom: 6.5rem !important;
}

.head-details {
  width: 31.8rem;
}

.cards-details {
  width: 30rem;
}

.button-card {
  background-color: #5f5f77 !important;
  color: white !important;
  font-weight: bold !important;
  text-transform: initial !important;
  box-shadow: initial !important;
  width: 9.25rem;
}

.button-card:hover {
  background-color: #f2bf30 !important;
  color: #0d0d1e !important;
}

.button-card-disabled {
  background-color: #0d0d1e !important;
  color: white !important;
  border: white 1px solid;
  box-shadow: initial !important;
  text-transform: initial !important;
  width: 9.25rem;
}

.hidden-component {
  display: inline;
}
.hidden-component-contact {
  display: inline;
}

.hidden-component-revert {
  display: none;
}

.yellow {
  color: #f2bf30 !important;
}

.white {
  color: white !important;
}

.text-base {
  font-weight: bold !important;
}

.policy {
  padding-top: 7.5rem;
  padding-left: 12rem;
  padding-right: 12rem;
}

.policies-tittle {
  /* font-family: SpaceGrotesk; */
  font-family: 'Space Grotesk', sans-serif;
  font-size: 4rem;
  font-weight: bold;
  color: white;
  padding-bottom: 7.5rem;
  text-transform: uppercase;
}

.policies-sub-tittle {
  /* font-family: SpaceGrotesk; */
  font-family: 'Space Grotesk', sans-serif;
  font-size: 2rem;
  font-weight: bold;
  padding-bottom: 1.5rem;
}

.policies-text {
  /* font-family: "SpaceGrotesk Light"; */
  font-family: 'Space Grotesk', sans-serif;
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.06;
  letter-spacing: 0.27px;
}

.policies-ul {
  /* font-family: "SpaceGrotesk Light"; */
  font-family: 'Space Grotesk', sans-serif;
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.06;
  letter-spacing: 0.27px;
  list-style-type: circle;

}

.pb-2rem {
  padding-bottom: 2rem;
}
.pb-2-5rem {
  padding-bottom: 2.5rem;
}

.arrow-home {
  display: inline;
}
.contact-items a{
  padding-left: 0px !important;
}
/* //////////---MOBILE---/////////// */
@media (max-width: 1300px){
  .navbar-container {
    z-index: 3;
    position: fixed !important;
    width: 100%;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
    /* scroll-behavior: inherit */
    /* overflow-y:scroll;
    overflow-x:hidden; */
  }
}
@media (max-width: 1023px) {
  .policy {
    padding-top: 3.5rem;
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .hidden-component {
    display: none !important;
  }

  .hidden-component-revert {
    display: inline;
  }
  .text-logo{
    width:100%;
  }
  .titles {
    line-height: 1.5;
    font-size: 2.125rem;
    padding-left: 2.563rem;
    padding-right: 2.563rem;
  }
  .icon-details {
    padding-bottom: 10px;
  }

  .titles-yellow {
    font-size: 2rem;
  }

  .p-sub-yellow {
    padding-right: 0%;
  }

  .head-details {
    width: auto;
    padding-top: 7rem;
    padding-bottom: 5rem;
  }

  .cards-details {
    width: auto;
  }

  .card-title-1 {
    font-size: 1.625rem;
  }

  .card-description {
    font-size: 1.125rem;
  }

  .card-img2 {
    width: 4.688rem;
  }

  .head-details {
    width: auto;
    padding-top: 7rem;
    padding-bottom: 5rem;
  }

  .cards-details {
    width: auto;
  }

  .card-title-1 {
    font-size: 1.625rem;
  }

  .card-description {
    font-size: 1.125rem;
  }

  .card-img2 {
    width: 4.688rem;
  }

  .head-details {
    width: auto;
    padding-top: 7rem;
    padding-bottom: 5rem;
  }

  .cards-details {
    width: auto;
  }

  .card-title-1 {
    font-size: 1.625rem;
  }

  .card-description {
    font-size: 1.125rem;
  }

  .card-img2 {
    width: 4.688rem;
  }

  .nav-links {
    justify-content: center;
  }

  .container-titles-details {
    width: auto;
    margin-top: 1.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-bottom: 0rem;
  }

  .container-titles-details-2 {
    margin-bottom: 0rem;
  }

  .container-titles .p1-gray {
    width: auto;
  }

  .long-description {
    width: auto;
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 1.67;
    color: #c1c1d3;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .container-titles-details .p1-gray {
    width: auto;
    /* font-family: SpaceGrotesk; */
    font-family: 'Space Grotesk', sans-serif;
    font-size: 1.125rem;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.39;
    letter-spacing: normal;
    color: #979797;
  }

  .container-titles-details-down {
    padding-left: 0rem;
    margin-bottom: 0rem;
  }

  .short-description {
    margin-right: 0rem;
    padding-left: 3.5rem;
    padding-right: 3.5rem;
    width: auto;
    font-size: 1.125rem;
    font-weight: bold;
    line-height: 1.67;
    text-align: center;
    color: #c1c1d3;
    padding-bottom: 5rem;
    padding-top: 5rem;
    background-image: url('./assets/elements/SVG ASSETS/Mobile.svg');
    background-repeat: no-repeat;
    background-size: contain;
  }

  .contact {
    width: auto;
    height: 40rem;
  }

  .container-bg2 {
    margin-top: 0rem;
    margin-bottom: -10rem;
    padding-left: 0%;
    width: max-content;
  }

  .text-over {
    left: 50%;
  }

  .container-bg-details {
    background-image: none;
  }

  .container-img {
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .titles-container {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .container-img {
    background-image: url('./assets/elements/SVG ASSETS/assets-vlines-mobile.svg');
  }

  .vertical-text {
    display: none;
    transform: rotate(0deg);
    font-size: 2.68rem;
    text-align: center !important;
  }

  .sub-title {
    padding: 4rem;
    font-size: 1.125rem;
    line-height: 1.5;
  }

  .sub-title-gray {
    width: auto;
    font-size: 1.125rem;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    padding-right: 2.75rem;
    padding-left: 2.75rem;
  }

  .justify-contact-items {
    justify-content: space-around !important;
  }

  .sub-title-gray-details {
    font-size: 1.125rem;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.01px;
    /* font-family: "SpaceGrotesk"; */
    font-family: 'Space Grotesk', sans-serif;
    color: #c1c1d3;
    width: auto;
    padding-bottom: 4rem;
  }

  .titles-max {
    font-size: 3rem;
  }

  .titles-max-details {
    font-size: 2.875rem;
    font-weight: 500 !important;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.11;
    padding-bottom: 2.5rem;
  }

  .titles-yellow-max-details {
    font-size: 2.875rem;
    font-weight: 500 !important;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.11;
  }

  .titles-max-contact-details {
    font-size: 3.063rem;
    font-weight: 500 !important;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.18;
  }

  .container-contact-details {
    padding-left: 2.8rem;
    padding-right: 2.8rem;
    background-image: url('./assets/elements/SVG ASSETS/assets-triangles-mobile.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: right 0px;
    padding-bottom: 5rem;
  }

  .footer {
    background-color:  #040414;
    padding-top: 3.5rem;
    padding-bottom: 2rem;
    padding-left: 1.5rem;
    padding-right: 1rem;
  }

  .navbar-container {
    z-index: 3;
    position: fixed !important;
    width: 100%;
    /* padding-left: 1rem !important;
    padding-right: 1rem !important; */
    /* scroll-behavior: inherit */
    /* overflow-y:scroll;
    overflow-x:hidden; */
  }

  .nav-icons {
    width: 2.75rem !important;
  }

  .footer .text {
    font-size: 1rem;
    font-weight: 300 !important;
    font-stretch: normal;
  }
  .footer .text2 {
    font-size: 1rem;
    font-weight: 300 !important;
    font-stretch: normal;
  }

  .footer .title {
    font-size: 1.125rem;
    font-weight: bold !important;
  }

  .bottom-title {
    opacity: 0.7;
    font-size: 0.75rem !important;
    font-weight: 300 !important;
  }

  .drop-item {
    padding-bottom: 1.5rem;
    padding-left: 1rem;
  }

  .dropdown {
    display: none !important;
  }

  .nav-collapse {
    background-color: #26273d;
    left: 0px;
    right: 0px;
  }

  .nav-bg {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .header-items {
    width: 100%;
  }
  .our-professional{
    padding-bottom: 6.5rem;
  }
  .logo-container {
    padding-left: 1.5rem;
  }
}

@media (max-width: 1279px) {
  .nav-collapse{
    grid-column: span 5 / span 5 !important;
  }
  .logo-header {
    grid-column: span 1 / span 1 !important;
}
}

@media (max-width: 1023px) {
  .nav-collapse{
    overflow-y:scroll !important;
    overflow-x:hidden !important;
    height: 40rem;
    grid-column: span 1 / span 1 !important;
    padding-bottom: 3rem;
  }
}
@media (max-width: 768px){
  .hidden-component-contact{
    display: none !important;
  }
}
@media (max-width: 768px){
.container-bg2 {
  width: auto;
}}